import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Parallax from 'react-springy-parallax';
import tw from 'tailwind.macro';

export const Divider = styled(Parallax.Layer)`
  ${tw`absolute w-full h-full`};
  background: ${props => props.bg};
  z-index: -40;
  svg {
    fill: ${props => props.fill};
  }
  clip-path: ${props => props.clipPath};
`;

const Content = styled(Parallax.Layer)`
  ${tw`flex items-center text-white p-6 md:p-12 lg:p-24`};
`;

const Inner = styled.div`
  ${tw`w-full xl:w-2/3`};
`;

const Trainings = ({ children }) => (
  <React.Fragment>
    <Divider bg="#000000" clipPath="polygon(0 7%, 100% 0%, 100% 93%, 0 100%)" speed={0.2} offset={1} />
    <Content speed={0.1} offset={1}>
      <Inner>{children}</Inner>
    </Content>
  </React.Fragment>
);

export default Trainings;

Trainings.propTypes = {
  children: PropTypes.node.isRequired
};

const twc = require('tailwindcss/defaultConfig');

module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Aikido dojo Baar', // Navigation and Site Title
  siteTitleAlt: 'Aikido dojo Baar', // Alternative Site title for SEO
  siteTitleShort: 'Aikido dojo Baar', // short_name for manifest
  siteUrl: 'https://aikidojo.ch', // Domain of your site. No trailing slash!
  siteLanguage: 'de', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: 'Home page of Aikido dojo Baar',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@aikidojobaar', // Twitter Username
  ogSiteName: 'aikidojobaar', // Facebook Site Name
  ogLanguage: 'de_CH', // Facebook Language

  // Manifest and Progress color
  themeColor: twc.theme.colors['red-darkest'],
  backgroundColor: twc.theme.colors.white,
};

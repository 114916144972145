import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import tw from "tailwind.macro";
import Parallax from "react-springy-parallax";
import { ShakeFly } from "../styles/animations";
import { SvgWrapper } from "../components/SVG";
import dragonfly from "../images/dragonfly.svg";
import rock1 from "../images/rock1.svg";
import rock2 from "../images/rock2.svg";
import rock3 from "../images/rock3.svg";

export const Divider = styled(Parallax.Layer)`
  ${tw`absolute w-full h-full`};
  background: ${props => props.bg};
  opacity: 1;
  z-index: -40;
`;

const Content = styled(Parallax.Layer)`
  ${tw`p-6 md:p-12 lg:p-24 flex items-center z-50`};
`;

const Inner = styled.div`
  ${tw`w-full md:w-4/5 text-center`};
`;

const Aikido = ({ children }) => (
  <React.Fragment>
    <Content speed={0.0} offset={2}>
      <Inner>{children}</Inner>
    </Content>
    <Divider speed={0.1} offset={2}>
      <div>
        <ShakeFly>
          <SvgWrapper svgWidth="3%" left="45%" top="80%" src={dragonfly} hiddenMobile/>
        </ShakeFly>
        <SvgWrapper svgWidth="10%" left="15%" top="80%" src={rock2} hiddenMobile/>
      </div>
    </Divider>
    <Divider speed={0.1} offset={2}>
      <SvgWrapper svgWidth="25%" left="30%" top="85%" src={rock1} hiddenMobile/>
    </Divider>
  </React.Fragment>
);

const Aikido2 = ({ children }) => (
  <React.Fragment>
    <Content speed={0.0} offset={3}>
      <Inner>{children}</Inner>
    </Content>
  </React.Fragment>
);

const Aikido3 = ({ children }) => (
  <React.Fragment>
    <Content speed={0.0} offset={4}>
      <Inner>{children}</Inner>
    </Content>
  </React.Fragment>
);

export { Aikido, Aikido2, Aikido3 };

export default Aikido;

Aikido.propTypes = {
  children: PropTypes.node.isRequired
};

Aikido2.propTypes = {
  children: PropTypes.node.isRequired
};

Aikido3.propTypes = {
  children: PropTypes.node.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Parallax from 'react-springy-parallax';
import tw from 'tailwind.macro';

const Content = styled(Parallax.Layer)`
  ${tw`p-6 md:p-12 lg:p-24 justify-center items-center flex z-50`};
`;

const Inner = styled.div`
  ${tw`w-full xl:w-2/3`};
`;

const Dojocho = ({ children }) => (
  <Content speed={0} offset={5}>
    <Inner>{children}</Inner>
  </Content>
);

export default Dojocho;

Dojocho.propTypes = {
  children: PropTypes.node.isRequired
};

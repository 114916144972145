import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Parallax from 'react-springy-parallax';
import tw from 'tailwind.macro';
import { theme } from '../../tailwind.config';
import { SvgWrapper } from '../components/SVG';
import aikido from '../images/aikido.svg';

const Wrapper = styled.div`
  ${tw`w-1/2`};
`;

const Content = styled(Parallax.Layer)`
  ${tw`flex p-6 md:p-12 lg:p-24 justify-center items-center z-50`};
`;

const Hero = ({ children }) => (
  <React.Fragment>
    <Parallax.Layer speed={-0.3} offset={0}>
      <SvgWrapper svgWidth={theme.width[`${64}`]} left="75%" top="0%" src={aikido} hiddenMobile />
    </Parallax.Layer>
    <Content speed={0.0} offset={0}>
      <Wrapper>{children}</Wrapper>
    </Content>
  </React.Fragment>
);

export default Hero;

Hero.propTypes = {
  children: PropTypes.node.isRequired
};

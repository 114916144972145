import React from 'react';
import styled from 'styled-components';
import tw from 'tailwind.macro';

import Parallax from 'react-springy-parallax';
import { theme } from '../../tailwind.config';

import urs from '../images/urs_aepli.png';
import aikiAi from '../images/aikido_ai.svg';
import aikiKi from '../images/aikido_ki.svg';
import aikiDo from '../images/aikido_do.svg';
import ch from '../images/ch.svg';
import sun from '../images/sun.svg';
import hill1 from '../images/hill1.svg';
import hill2 from '../images/hill2.svg';
import hill3 from '../images/hill3.svg';
import hill4 from '../images/hill4.svg';
import Layout from '../components/Layout';

import { SvgImage, SvgWrapper } from '../components/SVG';
import Hero from '../views/Hero';
import { BigTitle, Subtitle, Title } from '../elements/Titles';
import { Aikido, Aikido2, Aikido3 } from '../views/Aikido';
import { Contact, Map } from '../views/Contact';
import Trainings from '../views/Trainings';
import Dojocho from '../views/Dojocho';

const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`;

const Avatar = styled.img`
  ${tw`rounded-full w-64 xl:w-128 shadow-lg h-auto`};
`;

const AikidoAvatar = styled.img`
  ${tw`w-32 xl:w-48 h-auto`};
`;

const AiKiDoListWrapper = styled.div`
  ${tw`align-middle text-black px-4 py-2 m-2`};
`;

const Kanji = styled.img`
  ${tw`w-24`};
`;

const Def = styled.dd`
  padding-inline-start: 5rem;
  text-align: left;
`;

const AboutSub = styled.span`
  ${tw`font-sans pt-12 lg:pt-0 lg:pl-12 text-lg lg:text-2xl xl:text-3xl`};
`;

const Desc = styled.div`
  ${tw`font-sans text-base text-justify md:text-xl lg:text-2xl lg:w-2/3 pt-6 md:pt-12`};
`;

const ContactBox = styled.div`
  ${tw`font-sans text-base text-left md:text-xl lg:text-2xl lg:w-2/3 pt-6 md:pt-12`};
`;

const Footer = styled.footer`
  ${tw`align-middle text-center text-white absolute bottom-0 p-6 font-sans text-sm`};
`;

export const FooterDivider = styled(Parallax.Layer)`
  ${tw`absolute w-full h-full`};
  background-image: linear-gradient(${theme.colors.yellow[100]}, ${theme.colors.yellow[100]}, ${theme.colors.red[200]});
  z-index: -40;
`;

const TrainingsTable = styled.table`
  ${tw`text-white font-sans border-none w-2/3 text-small md:text-xl lg:text-2xl whitespace-no-wrap pt-6 md:pt-12`};
`;

const Th = styled.th`
  ${tw`text-white font-sans py-2 px-3 uppercase text-xl`}
`;

const Tr = styled.tr`
  ${tw`hover:bg-white hover:text-black`}
`;

const Td = styled.td`
  ${tw`py-2 px-3 border-b border-grey-light text-left`}
`;

class Index extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleScroll = value => {
      console.log('clicked ', value);
      this.parallax && this.parallax.scrollTo(value);
    };
  }

  render() {
    return (
      <React.Fragment>
        <Layout />
        <Parallax
          pages={8}
          scrolling
          ref={ref => {
            this.parallax = ref;
            return null;
          }}
        >
          <Parallax.Layer speed={-1.0} offset={6}>
            <SvgWrapper svgWidth={theme.width[`${128}`]} left="65%" top="10%" src={sun} />
          </Parallax.Layer>
          <Parallax.Layer speed={0.15} offset={6}>
            <SvgWrapper svgWidth={theme.width.full} bottom="0" src={hill1} />
          </Parallax.Layer>
          <Parallax.Layer speed={0.1} offset={6}>
            <SvgWrapper svgWidth={theme.width.full} bottom="0" src={hill2} />
          </Parallax.Layer>
          <Parallax.Layer speed={0.0} offset={6}>
            <SvgWrapper svgWidth={theme.width.full} bottom="0" src={hill3} />
          </Parallax.Layer>
          <Parallax.Layer speed={0.0} offset={6}>
            <SvgWrapper svgWidth={theme.width.full} bottom="0" src={hill4} />
            <Footer>
              &copy; {new Date().getFullYear()} Aikido dojo Baar. Made with <span className="text-red"> ❤ </span> in {' '}
              <SvgImage src={ch} svgWidth="2rem" /> by {' '} <a href="https://linkedin.com/in/robertkende" target="_blank">r0k3</a>
            </Footer>
          </Parallax.Layer>

          <Hero id="hero">
            <BigTitle>Herzlich willkommen bei Aikido Dojo Baar!</BigTitle>
            <Subtitle>
              Hier finden Sie alle Details zu unserem{' '}
              <a href="#" onClick={() => this.handleScroll(5)}>
                Dojo in Baar
              </a>{' '}
              und einige Infos zu{' '}
              <a href="#" onClick={() => this.handleScroll(2)}>
                Aikido
              </a>
              .
            </Subtitle>
            <Subtitle>
              Möchten Sie Aikido und unser Dojo kennenlernen? Details finden Sie unter{' '}
              <a href="#" onClick={() => this.handleScroll(1)}>
                Trainings
              </a>
              .
            </Subtitle>
          </Hero>

          <Trainings>
            <Title>
              Trainings | <i>keiko</i> | 稽古
            </Title>
            <Subtitle>Nehmen Sie unverbindlich ein Probetraining. Wir freuen uns auf Ihren Besuch!</Subtitle>
            <TrainingsTable>
              <thead>
                <tr>
                  <Th style={tw`text-right`} />
                  <Th style={tw`text-center`}>Zeit</Th>
                  <Th style={tw`text-left`}>Training</Th>
                </tr>
              </thead>
              <tbody>
                <Tr>
                  <Td style={tw`text-right`}>Dienstag</Td>
                  <Td style={tw`text-center`}>19:15-20:15</Td>
                  <Td style={tw`text-left`}>Aiki-Ken Aiki-Jo</Td>
                </Tr>
                <Tr>
                  <Td />
                  <Td style={tw`text-center`}>20:15-21:30</Td>
                  <Td style={tw`text-left`}>Aikido</Td>
                </Tr>
                <Tr>
                  <Td style={tw`text-right`}>Donnerstag</Td>
                  <Td style={tw`text-center`}>18:45-19:30</Td>
                  <Td style={tw`text-left`}>Aikido</Td>
                </Tr>
                <Tr>
                  <Td />
                  <Td style={tw`text-center`}>20:15-21:30</Td>
                  <Td style={tw`text-left`}>Aiki-Ken Aiki-Jo</Td>
                </Tr>
              </tbody>
            </TrainingsTable>
          </Trainings>

          <Aikido id="aikido">
            <Title>
              Aikido | <i>aikidō</i> | 合気道
            </Title>
            <Desc>
              Aikido wird als Kampfkunst und nicht als Kampfsport bezeichnet, da es auch keine Wettkämpfe gibt. Es geht
              darum, dass Angreifer und Verteidiger miteinander die Techniken lernen und nicht, dass man sich
              gegenseitig bekämpft.{' '}
              <p>
                Aikido ist Budo und Budo bedeutet: den Kampf beenden. Es bedeutet auch, zu kämpfen, wenn der Kampf
                unausweichlich ist. Dies sollte nicht aus technischer Sicht, sondern unter ethischen Betrachtungen
                verstanden werden.
              </p>
            </Desc>
          </Aikido>

          <Aikido2 id="aikido2">
            <Desc>
              Entstanden ist Aikido in Japan und wurde Anfang des 20. Jahrhunderts von Morihei Ueshiba entwickelt.
              Ueshiba wird auch "O-Sensei" (Großmeister) genannt.
              <p>
                Die Techniken des Aikido sind von den Techniken des Daito-Ryu Aiki-Jutsu und dem Schwertkampf geprägt.
              </p>
              <p>
                Aikido leitet die Kräfte des Angreifers um und verwendet dessen Dynamik zur Ausführung der Technik.
                Typisch sind die kreisrunden und spiralförmigen Techniken, mit denen die Angriffsenergie des Gegners
                nutzbar gemacht und verstärkt auf den Angreifer zurückgeführt wird.
              </p>
              <p>
                Die Kampfkunst ist für jede Person, egal welcher Altersgruppe, lernbar. Ein altes japanisches Sprichwort
                besagt: "Auch der längste Weg beginnt mit dem ersten Schritt".
              </p>
            </Desc>
          </Aikido2>

          <Aikido3 id="aikido3">
            <Desc>
              <AiKiDoListWrapper>
                <dl>
                  <dt>
                    <Kanji src={aikiAi} />
                  </dt>
                  <Def>Vereinigung, zusammenführen</Def>
                  <dt>
                    <Kanji src={aikiKi} />
                  </dt>
                  <Def>Geist, geistige Kraft, Energie, Lebenskraft</Def>
                  <dt>
                    <Kanji src={aikiDo} />
                  </dt>
                  <Def>Lebens- und Entwicklungsweg im Einklang mit der Natur</Def>
                </dl>
              </AiKiDoListWrapper>
            </Desc>
          </Aikido3>

          <Dojocho>
            <Title>
              Dojo Leitung | <i>dōjō-chō</i> | 道場長
            </Title>
            <AboutHero>
              <Avatar src={urs} alt="Urs Äepli" />
              <AboutSub>Urs Aepli, 6.Dan</AboutSub>
            </AboutHero>

            <Desc>
              Urs leitet das Aikido Dojo in Baar. Er besuchte Anfang der 80er Jahre einen Anfängerkurs im Aikido Dojo in
              St.Gallen. Ab 1984 trainierte er zusätzlich im Dojo in Rapperswil. Zwei Jahre später trainierte er in den
              Dojos Birmensdorf und Wohlen. Im März 1988 trat er in das Aikido Dojo von Ikeda Sensei ein. Von 1989 bis
              1995 konnte er erste Unterrichtserfahrungen im Dojo Wohlen sammeln. 1991 nahm er an der vierwöchigen
              Rundreise mit Ikeda Sensei in Japan teil. Ab 1994 leitete er eines der Trainings Ikeda Dojo Baar“. Das
              Dojo in Baar wurde damals noch unter der Leitung von Ikeda Sensei geführt. 1998, 1999 und im 2000 durfte
              er als Vertretung von Ikeda Sensei in Split, Kroatien ein Wochenend-Lehrgang betreuen.
              <p>
                Als 2002 Ikeda Sensei sich aus gesundheitlichen Gründen zurückzog übernahm Urs Aepli das Dojo Baar 2003
                unter dem Namen „Aikido Dojo Baar“. Gleichzeitig leitete er auch wöchentlich ein Training im ehemaligen
                Ikeda Dojo in Zürich bis 2010. Seit 2003 besucht er regelmässig Japan, wo er sich in verschiedenen Dojos
                weiterbildet.
              </p>
            </Desc>
          </Dojocho>

          <Contact>
            <Title>Kontakt | 連絡する。</Title>
            <ContactBox>
              <address>
                Aikido Dojo Baar,{' '}
                <a href="#" onClick={() => this.handleScroll(7)}>
                  Bachweid 3
                </a>
                , 6340 Baar
                <br />
                <br />
                Für Informationen oder Anmeldungen wenden Sie sich an Urs Aepli
                <br />
                <a href="tel:+41 44 273 29 37">☎ 044 273 29 37</a>&nbsp;|&nbsp;
                <a href="tel:+41 76 725 81 20"> ☎ 076 725 81 20</a>.
              </address>
            </ContactBox>
          </Contact>

          <FooterDivider speed={0.0} offset={6} />

          <Map />
        </Parallax>
      </React.Fragment>
    );
  }
}

export default Index;

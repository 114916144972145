import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Title = styled.h1`
  ${tw`relative inline-block tracking-wide font-sans text-xl lg:text-3xl mb-8 `};
`;

export const BigTitle = styled.h1`
  ${tw`text-2xl lg:text-4xl font-sans mb-6 tracking-wide`};
`;

export const Subtitle = styled.p`
  ${tw`text-xl lg:text-2xl font-sans mt-8 xl:w-3/4`};
`;

import React from 'react';
import GlobalStyle from '../styles/global';
import SEO from './SEO';
import 'typeface-cantata-one';
import 'typeface-open-sans';

const Layout = () => (
  <React.Fragment>
    <SEO />
    <GlobalStyle />
  </React.Fragment>
);

export default Layout;

import React from 'react';
import PropTypes from 'prop-types';
import tw from 'tailwind.macro';
import styled from 'styled-components';
import Parallax from 'react-springy-parallax';
import { theme } from '../../tailwind.config';

const Wrapper = styled.div`
  ${tw`w-1/2`};
`;

const Content = styled(Parallax.Layer)`
  ${tw`flex justify-start items-center text-left mt-16 p-10 md:p-12 lg:p-24 z-50`};
`;

const IframeContent = styled(Parallax.Layer)`
  ${tw`p-0 border-0`};
  margin-bottom: 10rem;
`;

const Inner = styled.div`
  ${tw`text-left text-sm p-3 border-dashed`};
  background: ${theme.colors.white};
  opacity: 0.86;
`;

const IframeContainer = styled.div`
  overflow: hidden;
  height: 100%;
  padding-top: 0%;
  position: relative;
`;

const mapIframe =
  '<iframe width="100%" height="100%" frameborder=0 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2710.7371110293498!2d8.524023755404475!3d47.202157811210924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aaa2510198ecf%3A0xcee656ad15543cc4!2sBachweid+3%2C+6340+Baar!5e0!3m2!1sen!2sch!4v1550533309552" allowFullScreen></iframe>';

const Contact = ({ children }) => (
  <Content speed={0.0} offset={6}>
    <Inner>{children}</Inner>
  </Content>
);

const Map = () => (
  <IframeContent speed={0.0} offset={7}>
    <IframeContainer dangerouslySetInnerHTML={{ __html: mapIframe }} />
  </IframeContent>
);

export { Map, Contact };

Contact.propTypes = {
  children: PropTypes.node.isRequired,
};
